angular.module('BookingApp')

.service "PrioritiseTranslationModifier", [

  "$rootScope"
  ($rootScope) ->

    this.perform = (hotels) ->
      return hotels if !hotels

      for hotel in hotels
        if hotel.original_metadata && hotel.original_metadata.name
          hotel.searchRank -= 2 if hotel.original_metadata.name != hotel.name

      return hotels

    return this
]
